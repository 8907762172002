import React from "react"
import { Row, Col, Container } from "reactstrap"
import Layout from "../components/Layout"
import HelmetTitle from "../components/utils"
import appSecurityImage from "../images/app-security-main-pic.png"
import "../scss/modules/_m-hero-app-security.scss"
import "../scss/app.scss"
import icon1 from "../images/appsecurity_icon1.png"
import icon2 from "../images/appsecurity_icon2.png"
import icon3 from "../images/appsecurity_icon3.png"
import icon4 from "../images/appsecurity_icon4.png"
import icon5 from "../images/appsecurity_icon5.png"
import icon6 from "../images/appsecurity_icon6.png"

const AppSecurity = () => {
  const title = "Application Security",
    tagline = `Due to the pressure to release quickly, the in-depth security checks are often incomplete which will 
      result in vulnerabilities that can be exploited. At Foretheta, we fill that gap by providing comprehensive 
      end-to-end security assessment that includes code review, threat analysis, penetration testing and more.`
  return (
    <Layout>
      <div className="mb-6">
        <HelmetTitle title="App Security" />
        <div className="app-security-banner pt-6 pb-6 text-white text-justify">
          <Container fluid>
            <Row>
              <Col xl={{ size: 10, offset: 1 }}>
                <h1 className="mb-5 font-weight-bold">{title} </h1>
                <h3 className="mb-5 text-justify">{tagline}</h3>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="py-4 grey-background" fluid>
          <Row>
            <Col
              xl={{ size: 4, offset: 1 }}
              md={{ size: 4, offset: 0 }}
              sm={{ size: 4, offset: 2 }}
              xs={{ offset: 1 }}
              className="web-app-img-col"
            >
              <img src={appSecurityImage} alt="Application Security icon" className="img-responsive app-security-img" />
            </Col>

            <Col
              xl={{ size: 6, offset: 0 }}
              lg={{ size: 6, offset: 1 }}
              md={{ size: 6, offset: 2 }}
              sm={{ size: 12, offset: 0 }}
              xs={{ offset: 0 }}
            >
              <h4 className="font-weight-bold app-security-header mt-2">Making web applications safe</h4>
              <br />
              <p>
                No matter what service you’re providing through your application, security should be among the top
                priorities. Companies that neglect to secure their applications are risking to lose customer trust or
                damaged reputation.
              </p>
              <p>
                The big challenge is how to keep up with the latest attack methods to maintain a high-security level. At
                Foretheta, our experts are always seeking the latest updates in the security realm to provide a
                comprehensive assessment with actionable advice.
              </p>
            </Col>
          </Row>
        </Container>

        <h4 className="text-center app-security-header mb-4 mt-5 font-weight-bold">
          Our application security services
        </h4>

        <Container className="services" fluid>
          <Row noGutters>
            <Row className="mt-5 mb-4" noGutters>
              <Col xl={{ size: 1, offset: 1 }} xs={{ size: 1, offset: 0 }} className="mb-4">
                <img src={icon1} alt="Application security icon" />
              </Col>
              <Col xl={2} md={3} sm={{ size: 5, offset: 0 }} xs={{ size: 10, offset: 1 }} className="mb-4">
                <div className="px-md-0 pl-sm-4 pl-4">
                  <h5 className="app-icon-header">Penetration testing</h5>
                  <p>
                    We identify vulnerabilities in your application and attempting to exploit them to penetrate into the
                    system
                  </p>
                </div>
              </Col>

              <Col xs={1} className="ml-xl-3 ml-lg-0 ml-md-0 mb-4">
                <img src={icon2} alt="Application security icon" />
              </Col>
              <Col
                xl={2}
                md={3}
                sm={{ size: 5, offset: 0 }}
                xs={{ size: 10, offset: 1 }}
                className="mr-xl-3 mr-lg-0 mr-md-0 mb-4"
              >
                <div className="px-md-0 pl-sm-4 pl-4">
                  <h5 className="app-icon-header">Vulnerability assessment</h5>
                  <p>We scan your application and estimate how susceptible it is to different vulnerabilities</p>
                </div>
              </Col>

              <Col xs={1} className="mb-4">
                <img src={icon3} alt="Application security icon" />
              </Col>
              <Col xl={2} md={3} sm={{ size: 5, offset: 0 }} xs={{ size: 10, offset: 1 }} className="mb-4">
                <div className="px-md-0 pl-sm-4 pl-4">
                  <h5 className="app-icon-header">Security Infrastructure</h5>
                  <p>We help you protect your infrastructure from internal and external malicious attacks</p>
                </div>
              </Col>

              <Col xl={{ size: 1, offset: 1 }} xs={{ size: 1, offset: 0 }} className="mb-4">
                <img src={icon4} alt="Application security icon" />
              </Col>
              <Col xl={2} md={3} sm={{ size: 5, offset: 0 }} xs={{ size: 10, offset: 1 }} className="mb-4">
                <div className="px-md-0 pl-sm-4 pl-4">
                  <h5 className="app-icon-header">Security Analysis</h5>
                  <p>
                    We identify security issues and provide guidance on how to cure them and make your application
                    considerably safe
                  </p>
                </div>
              </Col>

              <Col xs={1} className="ml-xl-3 ml-md-0 mb-4">
                <img src={icon5} alt="Application security icon" />
              </Col>
              <Col
                xl={2}
                md={3}
                sm={{ size: 5, offset: 0 }}
                xs={{ size: 10, offset: 1 }}
                className="mr-xl-3 mr-md-0 mb-4"
              >
                <div className="px-md-0 pl-sm-4 pl-4">
                  <h5 className="app-icon-header">Security Engineering</h5>
                  <p>We build security components into your application and resolve any security incidents</p>
                </div>
              </Col>

              <Col xs={1} className="mb-4">
                <img src={icon6} alt="Application security icon" />
              </Col>
              <Col xl={2} md={3} sm={{ size: 5, offset: 0 }} xs={{ size: 10, offset: 1 }} className="mb-4">
                <div className="px-md-0 pl-sm-4 pl-4">
                  <h5 className="app-icon-header">Web Application Firewall</h5>
                  <p>
                    We set up firewalls that filters, monitors, and blocks HTTP traffic to and from your web application
                  </p>
                </div>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default AppSecurity
